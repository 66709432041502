.gallery-container {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 50px 20px;
}

.gallery-title {
  font-size: 42px;
  margin-bottom: 30px;
  color: #007bff; /* Blue color */
  font-weight: bold; /* Bold font */
}

.folders {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.folder {
  cursor: pointer;
  padding: 20px 30px;
  margin: 15px;
  border: 2px solid #007bff; /* Blue border */
  border-radius: 10px;
  background-color: #ffffff; /* White background */
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 24px;
  color: #000000; /* Black color */
  font-weight: bold; /* Bold font */
}

.folder:hover {
  background-color: #007bff; /* Blue background on hover */
  color: #ffffff; /* White color on hover */
}

.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}

.images img {
  width: 280px;
  height: 200px;
  object-fit: cover;
  margin: 20px;
  border: 3px solid #007bff; /* Blue border */
  border-radius: 15px;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.images img:hover {
  transform: scale(1.05);
  border-color: #0056b3; /* Darker blue border on hover */
}
