.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.ourteams-container {
  margin-bottom: 20px;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.0s ease-in-out, opacity 0.0s ease-in-out;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  object-fit: cover;
  border: 3px solid #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ourteams-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 5px;
}

.designation {
  font-size: 16px;
  color: #888;
  margin: 0;
}

.resume {
  font-size: 20px;
  color: #555;
  margin-top: 15px;
  text-align: justify; /* Added to justify text */
}

.teamAbout {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  text-align: justify; /* Added to justify text */
}
