/* Service.css */

.service-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.service-title {
  color: #333;
  font-size: 3em;
  margin-bottom: 20px;
  font-weight: bold;
}

.divider {
  width: 20%;
  margin: auto;
  margin-bottom: 30px;
  border: 2px solid #666; /* Darker border color */
}

.service-content {
  color: #555; /* Slightly darker text color */
  font-size: 1.2em;
  margin-bottom: 30px;
}

.expert-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery {
  margin: 20px;
  width: 300px; /* Set a fixed width for uniformity */
  height: 400px; /* Set a fixed height for uniformity */
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.gallery img {
  width: 100%;
  height: 50%;
  object-fit: cover;
  display: block;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.desc {
  background-color: #f7f7f7; /* Light background color */
  padding: 20px;
  box-sizing: border-box;
  text-align: left; /* Align text to the right */
}

.desc h3 {
  color: #333;
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: bold; /* Make the heading bold */
}

.desc p {
  color: #666;
  font-size: 1.1em;
  margin: 0;
  text-align: justify; /* Add this line */
}