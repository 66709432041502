/* Aim.css */

.aim-container {
  padding: 50px 0;
  background-color: #f0f4f8; /* Light blue background */
}

.aim-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aim-paper {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
}

.aim-title {
  font-size: 2.5rem; /* Increase font size */
  color: #007bff; /* Blue color */
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif; /* Use Roboto font */
}

.aim-title-bold {
  font-weight: bold; /* Make the title bold */
}

.separator {
  width: 50px;
  margin: 20px auto;
  background-color: #007bff; /* Blue color */
}

.aim-content {
  color: #6c757d;
  text-align: justify;
  line-height: 1.6;
  font-family: 'Roboto', sans-serif; /* Use Roboto font */
}

.aim-image {
  width: 100%;
  max-width: 400px;
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
}
