@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.Home {
  font-family: 'Montserrat', sans-serif; /* Montserrat font */
  font-weight: normal;
  padding: 1rem;
}

.eduplan {
  color: #007bff; /* Corporate color */
}

.hero-section {
  position: relative;
  width: 75%;
  float: left;
  padding: 2rem;
  border: 2px solid #ddd; /* Subtle border */
  background-color: #f5f5f5;
  border-radius: 10px; /* Subtle border radius */
}

.hero-text {
  position: absolute;
  bottom: 100px; /* Adjust as needed */
  left: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  color: #fff; /* White text */
  z-index: 1;
}

.hero-text h1 {
  font-size: 40px; /* Increased font size for h1 */
  font-weight: bold; /* Bold font */
}

.hero-text p,
.hero-buttons button {
  font-size: 19px;
  font-weight: bold;
}

.service-cards {
  width: 25%;
  float: right;
  text-align: justify;
  padding: 0rem 1rem;
  font-family: 'Montserrat', sans-serif; /* Montserrat font */
  margin-top: 0; /* Remove top margin */
}

.card {
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 5px; /* Subtle border radius */
  padding: 1rem;
}

.card h2 {
  font-size: 28px; /* Increased font size for h2 */
  margin-bottom: 10px;
  color: #333;
  font-weight: bold; /* Bold heading */
}

.card h3 {
  font-size: 24px; /* Increased font size for h3 */
  margin-top: 10px;
  margin-bottom: 5px;
  color: #333;
  font-weight: bold; /* Bold heading */
}

.card p {
  font-size: 20px; /* Increased font size for paragraphs */
  line-height: 1.5;
}

.pagination {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100px;
  margin-left: 40px;
  margin-right: 40px; /* Set a fixed height for vertical alignment */
}

.dot {
  /* Existing styles */
  margin: 0 5px;
}

@media (max-width: 768px) {
  .Home {
    padding: 0.5rem;
  }

  .hero-section {
    width: 100%;
    float: none;
    border-radius: 0; /* Remove border radius */
    padding: 1.5rem 1rem;
    margin-bottom: 1rem;
  }

  .hero-text {
    bottom: 20px;
    left: 10px;
    right: 10px;
  }

  .service-cards {
    width: 100%;
    float: none;
    margin-top: 0;
    padding: 0 1rem;
  }

  .card {
    margin-bottom: 1rem;
  }
}
