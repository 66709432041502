body, html {
  height: 100%;
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  margin-top: auto; 
  background-color: darkblue; 
  color: white;
  text-align: center;
  padding: 20px 0;
  font-family: Arial, sans-serif; /* Font family for the footer */
}

.footer-content {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  max-width: 1300px;
  margin: auto;
  padding: 0 20px;
}

.footer-info h3.contactUs {
  font-size: 1.2em; /* Increase the font size */
  font-weight: bold; /* Make it bold */
  margin-bottom: 10px; /* Add some space below the heading */
}

.footer-info p {
  font-size: 1em; /* Set the font size */
  line-height: 1.5; /* Adjust line height */
}

.contactUs {
  margin-left: 0%;
  padding-left: 0%;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .footer-content {
    flex-direction: row; 
    justify-content: space-around; 
    text-align: left; 
  }

  .footer-logo, .footer-info {
    flex: 1; 
  }

  .footer-logo img {
    width: 25vw; /* Adjust logo size for larger screens */
    margin-left: 0px;
  }

  .footer-info {
    padding-left: 15rem; /* Adjust padding for larger screens */
  }
}

/* Additional responsive adjustments */
@media (max-width: 767px) {
  .footer-content {
    flex-direction: column; /* Display content in a column on smaller screens */
  }

  .footer-logo img {
    width: 50%; /* Adjust logo size for smaller screens */
    margin-bottom: 20px;
  }

  .footer-info {
    padding-left: 0; /* Remove left padding for smaller screens */
  }
}
